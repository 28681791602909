import { css } from '@emotion/react'
import { colors } from '@orus.eu/pharaoh'

/**
 * Level of progress displayed before actually entering the funnel
 */

export const subscriptionStepOuterElementCss = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden scroll;
  background-color: ${colors.linen};
`

export const subscriptionStepOuterElementCssMobile = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden scroll;
  background-color: white;
`
