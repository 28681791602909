import { Avatar, Button, secondaryColor, useTranslate } from '@orus.eu/pharaoh'
import { openChat } from '../../../../lib/hubspot-util'

export function SubscriptionHeaderContactButton(): JSX.Element {
  const translate = useTranslate()
  return (
    <Button
      variant="secondary"
      onClick={openChat}
      avatar={<Avatar icon="comment-question-duotone" color={secondaryColor} secondaryColor={secondaryColor} />}
      size="medium"
      avatarPosition="left"
      trackingId="contact_button"
    >
      {translate('contact_customer_support')}
    </Button>
  )
}
