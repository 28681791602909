import { css } from '@emotion/react'
import { LinearProgress } from '@mui/material'
import { memo } from 'react'
import { useTransitionHelper } from '../../../../lib/use-transition-helper'
import { progressBarHeight } from './subscription-progress-bar-height'

export const SubscriptionV2ProgressBar = memo<{
  progress: number
  className?: string
}>(function SubscriptionHeaderProgressBar({ progress, className }) {
  // Note 1 : We don't want to start with a progress bar too close to zero to avoid demotivation
  // Note 2 : LinearProgress required a value between 0 and 100
  const progressBarValue = 20 + 80 * progress

  const animatedValue = useTransitionHelper(transitionId, progressBarValue)
  return (
    <LinearProgress
      css={css`
        height: ${progressBarHeight}px;
      `}
      variant="determinate"
      value={animatedValue}
      className={className}
    />
  )
})

const transitionId = Symbol('subscription header v2')
