import { css } from '@emotion/react'
import { colors } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { progressBarHeight } from './subscription-progress-bar-height'

export const SubscriptionV2HeaderSeparator = memo(function SubscriptionV2HeaderSeparator() {
  return (
    <hr
      css={css`
        height: ${separatorHeight}px;
        border-top: ${lineThickness}px solid ${colors.gray[100]};
        border-bottom: none;
        border-right: none;
        border-left: none;
        margin: 0;
      `}
    />
  )
})

const lineThickness = 1
const separatorHeight = progressBarHeight
