import { css } from '@emotion/react'
import { mobileSideSpace, spacing, useScreenType } from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'

type SubscriptionV2ColumnProps = {
  children: ReactNode
  className?: string
}
export const SubscriptionV2Column = memo<SubscriptionV2ColumnProps>(function SubscriptionV2Column({
  children,
  className,
}) {
  const screenType = useScreenType()
  return (
    <div
      className={className}
      css={
        screenType === 'desktop'
          ? css`
              width: 100%;
              max-width: 1352px;
              margin: 0 auto;
              padding: 0 ${spacing[50]};
            `
          : css`
              padding: 0 ${mobileSideSpace};
            `
      }
    >
      {children}
    </div>
  )
})
