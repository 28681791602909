import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { colors, desktopMediaQuery, spacing, useScreenType } from '@orus.eu/pharaoh'
import { memo, type ReactNode } from 'react'
import { stickyHeaderZIndex } from '../../../../lib/z-indexes'
import { SubscriptionV2HeaderSeparator } from './header-separator'
import { SubscriptionV2Column } from './subscription-v2-column'
import { SubscriptionV2ProgressBar } from './subscription-v2-progress-bar'

export type SubscriptionStepHeaderLayoutProps = {
  children: ReactNode
  compact?: boolean
  progress: number
}
export const SubscriptionStepHeaderLayout = memo<SubscriptionStepHeaderLayoutProps>(
  function SubscriptionStepHeaderLayout({ children, compact, progress }) {
    return (
      <div
        css={css`
          position: sticky;
          top: 0;
          z-index: ${stickyHeaderZIndex};
        `}
      >
        <div
          css={css`
            background-color: ${colors.white};
          `}
        >
          <SubscriptionV2Column>{children}</SubscriptionV2Column>
        </div>

        {compact ? <SubscriptionV2HeaderSeparator /> : <SubscriptionV2ProgressBar progress={progress} />}
      </div>
    )
  },
)

export type SubscriptionStepLayoutProps = {
  left?: ReactNode
  center: ReactNode
  right: ReactNode | undefined
  compact?: boolean
}

export const SubscriptionStepLayout = memo<SubscriptionStepLayoutProps>(function SubscriptionStepLayout({
  left,
  center,
  right,
  compact,
}) {
  return (
    <SubscriptionStepLayoutDiv compact={compact}>
      {left && (
        <SubscriptionStepLayoutLeftDiv>
          <StickySideHelper>{left}</StickySideHelper>
        </SubscriptionStepLayoutLeftDiv>
      )}

      <SubscriptionStepLayoutCenterDiv>{center}</SubscriptionStepLayoutCenterDiv>

      {right ? (
        <SubscriptionStepLayoutRightDiv>
          <StickySideHelper>{right}</StickySideHelper>
        </SubscriptionStepLayoutRightDiv>
      ) : (
        <></>
      )}
    </SubscriptionStepLayoutDiv>
  )
})

type StickySideHelperProps = { children: ReactNode }
const StickySideHelper = memo<StickySideHelperProps>(function StickySideHelper({ children }) {
  const screenType = useScreenType()
  return (
    <div
      css={
        screenType === 'desktop'
          ? css`
              position: sticky;
              top: 116px;
            `
          : undefined
      }
    >
      {children}
    </div>
  )
})

const SubscriptionStepLayoutDiv = styled(SubscriptionV2Column)<{ compact?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.compact ? spacing[60] : spacing[100])};
  margin-bottom: ${spacing[100]};

  ${desktopMediaQuery} {
    flex-direction: row;
  }
`

const SubscriptionStepLayoutLeftDiv = styled.div`
  display: none;
  flex: 0 0 216px;
  margin-top: ${spacing[60]};

  ${desktopMediaQuery} {
    display: initial;
    margin-top: ${spacing[80]};
  }
`

const SubscriptionStepLayoutCenterDiv = styled.div`
  flex: 1 1 0%;
  margin-top: ${spacing[60]};

  ${desktopMediaQuery} {
    flex: 1 1 100%;
    margin-top: ${spacing[80]};
  }
`
const SubscriptionStepLayoutRightDiv = styled.div`
  flex: 0 0 0%;

  ${desktopMediaQuery} {
    flex: 0 0 326px;
  }
`
